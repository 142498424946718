import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import PostForm from '../../components/forms/PostForm';
import { UserContext } from '../../App';
import { Button, notification } from 'antd';

const EditPost = (props) => {

  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [post, fetchPost] = useAxios('', {}, currentuser.data.token, 'get');
  const [postImage, fetchpostImage] = useAxios('', null, currentuser.data.token, 'get');
  const [tags, fetchTags] = useAxios('', null, currentuser.data.token, 'get');
  const { imgId, postId } = props.match.params;

  useEffect(() => {
    if (postId) fetchPost(`${SERVER_URL}/posts/${postId}`, {});
    if (imgId) fetchpostImage(`${SERVER_URL}/images/${imgId}`, {});
    fetchTags(`${SERVER_URL}/tags`, []);
  }, [fetchPost, fetchpostImage, postId, imgId, fetchTags]);


  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/posts` : `${SERVER_URL}/posts/${postId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });


      notification.success({
        message: `Post ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin/posts');
    } catch (error) {
      console.log('error::', error);
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }

    // if(!isNew){
    //   const methodDraft = 'post';
    //   const routeDraft = `${SERVER_URL}/posts`;
    //   try {
    //     await Axios[methodDraft](routeDraft, formData, {
    //       withCredentials: false,
    //       headers: { Authorization: `Bearer ${currentuser.data.token}` },
    //     });
    //
    //     notification.success({
    //       message: `Post ${isNew ? 'created.' : 'updated.'}`,
    //       placement: 'bottomRight',
    //     });
    //     history.push('/admin/posts');
    //   } catch (error) {
    //     console.log('error::', error);
    //     const msg = error.response ? error.response.data.message : error.message ? error.message : error;
    //     console.log(msg);
    //     notification.error({
    //       message: msg,
    //       placement: 'bottomRight',
    //     });
    //   }
    // }

  };
  const isDataFetched = !post.isLoading && post.data && currentuser.language && !tags.isLoading && tags.data && !postImage.isLoading;
  // console.log(tags);
  // console.log(currentuser.language);
  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/posts'>
          <Button type='primary'>All posts</Button>
        </Link>
      </div>
      <div>
        {!postId && isDataFetched && (
          <PostForm
            isNew={true}
            image={postImage.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            tags={tags.data.items}
          />
        )}
        {postId && !post.isError && post.data && isDataFetched && (
          <PostForm
            isNew={false}
            data={post.data}
            image={postImage.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            tags={tags.data.items}
          />
        )}
      </div>
    </div>

  );
};

export default EditPost;