import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Layout, Tabs } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { GalleryForm } from '../../components/forms';
import { GalleryTable } from '../../components/tables';

const { Content } = Layout;
const { TabPane } = Tabs;

const TABLE_COLUMN_KEYS = ['_id', '__v', 'key', 'updatedAt', 'uploadedBy', 'url', 'forMobile'];

const GalleryEditContent = (props) => {
  const { fromPage, itemId } = props.match.params;
  const currentuser = useContext(UserContext);
  // const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [images, fetchImages] = useAxios('', null, currentuser.data.token, 'get');
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    fetchImages(`${SERVER_URL}/images`, []);
  }, [fetchImages]);

  let columnKeys;
  if (images && images.data && images.data.length > 0) {
    const keys = Object.keys(images.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (images && images.data && images.data.length > 0) {
    tableData = images.data.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  const selectHandler = (value) => {
    // MULTISELECT
    // if (selected.includes(value)) {
    //   const removed = selected.filter((item) => item !== value);
    //   setSelected(removed);
    // } else {
    //   setSelected([...selected, value]);
    // }

    // SINGLE SELECT
    setSelected([value]);
  };

  const imageData = () => {
    let savePath = 'public/images/',
      type = 'common',
      height = 500;
    switch (fromPage) {
      case 'data-categories':
        savePath += 'categories/';
        type = 'category';
        height = 100;
        break;
      case 'data-attributes':
        savePath += 'attributes/';
        type = 'attribute';
        height = 100;
        break;
      case 'edit-data':
      case 'new-data':
        savePath += 'data/';
        type = 'data';
        break;
      case 'edit-user':
      case 'new-user':
        savePath += 'user/';
        type = 'user';
        height = 100;
        break;
      case 'edit-post':
      case 'new-post':
        savePath += 'post/';
        type = 'post';
        break;
      case 'new-tag':
      case 'edit-tag':
        savePath += 'tag/';
        type = 'tag';
        height = 100;
        break;
      default:
        break;
    }
    return { savePath, type, height };
  };

  return (
    <div className='content-wrapper'>
      <div className='actions-block'>
        <Link
          to={
            selected[0]
              ? fromPage.includes('new')
                ? `/admin/${fromPage}/${selected[0]}`
                : `/admin/${fromPage}/${selected[0]}/${itemId}`
              : fromPage.includes('edit')
              ? `/admin/${fromPage}/${itemId}`
              : `/admin/${fromPage}/none`
          }
        >
          <Button type='primary'>Insert</Button>
        </Link>
      </div>

      <div className='content'>
        {/* {itemId && images.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />} */}
        {itemId && !images.isLoading && images.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        <Layout className='site-layout-background'>
          <Content style={{ padding: '0 0 0 15px', minHeight: 280 }}>
            <Tabs defaultActiveKey='1'>
              <TabPane tab='GALLERY' key='1'>
                <div className='content'>
                  {images && images.isLoading && (
                    <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem', marginLeft: '50%', transform: 'translateX(-50%)' }} />
                  )}
                  {images && !images.isLoading && images.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
                  {images && !images.isLoading && !images.isError && images.data && images.data.length > 0 && (
                    <GalleryTable data={tableData} columnKeys={columnKeys} title='Gallery' selectHandler={selectHandler} selected={selected} />
                  )}
                  {images && images.data && !images.isLoading && images.data.length === 0 && <h2>NO IMAGES</h2>}
                </div>
              </TabPane>
              <TabPane tab='UPLOAD' key='2'>
                <div className='content'>
                  <GalleryForm
                    token={currentuser.data.token}
                    selectHandler={selectHandler}
                    imageSavePath={imageData().savePath}
                    imageType={imageData().type}
                    imageHeight={imageData().height}
                  />
                </div>
              </TabPane>
            </Tabs>
          </Content>
        </Layout>
      </div>
    </div>
  );
};

export default GalleryEditContent;
