import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import slugify from 'slugify';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Row, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { CategoryForm, CategoryTree } from '../../components/forms';

const Categories = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [categories, fetchCategories] = useAxios('', [], currentuser.data.token, 'get');
  const [image, fetchImage] = useAxios('', [], currentuser.data.token, 'get');
  const [edit, setEdit] = useState({ visible: false, category: {}, parent: {} });
  const [treeData, setTreeData] = useState([]);
  const { imgId, categoryId } = props.match.params;

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, []);
    if (imgId) fetchImage(`${SERVER_URL}/images/${imgId}`, {});
    if (categoryId && categories.data.length > 0) {
      const selectedCategory = categories.data.find((c) => c._id === categoryId);
      setEdit({ visible: true, category: selectedCategory || {}, parent: selectedCategory ? selectedCategory.parent : {} });
    }
  }, [fetchCategories, fetchImage, imgId, categoryId, categories.data]);

  useEffect(() => {
    let data = [];
    if (currentuser.language) {
      data = getTreeFromFlatData({
        flatData: categories.data.map((node) => ({ ...node, title: node.name[currentuser.language.selected.code] })),
        getKey: (node) => node._id, // resolve a node's key
        getParentKey: (node) => node.parent, // resolve a node's parent's key
        rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
      });
    }
    data =
      data.length > 0
        ? data.map((data) => ({ ...data, expanded: data.children && data.children.length > 0 && data.children.some((child) => child._id === categoryId) }))
        : [];
    setTreeData(data);
  }, [categories, currentuser]);

  const deleteHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/categories/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Category deleted',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data-categories');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const editHandler = (id, parentNode) => {
    if (parentNode) {
      setEdit({ visible: true, category: { parent: parentNode }, parentNode });
    } else {
      setEdit({ visible: true, category: categories.data.find((c) => c._id === id), parentNode });
    }
  };

  const removeImage = async (imageKey) => {
    try {
      await Axios.post(
        `${SERVER_URL}/remove-file/${imageKey}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
    } catch (error) {
      console.log(error.response ? error.response.data.message : error.message);
    }
  };

  const onSubmit = async (data, isNew) => {
    data.url = slugify(data.name[currentuser.language.default.code], { lower: true });
    if (data.image === '') delete data.image;
    if (data.image && edit.category.image && edit.category.image._id !== data.image) await removeImage(edit.category.image.key);
    if (edit.category.parent) data.parent = edit.category.parent;

    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/categories` : `${SERVER_URL}/categories/${edit.category._id}`;

    try {
      await Axios[method](route, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Category ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data-categories');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Button type='primary' onClick={() => setEdit({ visible: true, category: {}, parentNode: {} })}>
          Add category
        </Button>
      </div>

      <Row type='flex' gutter={8}>
        <Col xs={24} md={edit.visible ? 16 : 24}>
          <div className='panel panel-primary' style={{ textAlign: 'center', padding: '8px' }}>
            {categories.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
            {!categories.isLoading && categories.data && categories.data.length > 0 && (
              <CategoryTree
                treeData={treeData}
                setTreeData={setTreeData}
                token={currentuser.data.token}
                language={currentuser.language && currentuser.language.selected.code}
                setEdit={setEdit}
                editHandler={editHandler}
                deleteHandler={deleteHandler}
              />
            )}
            {!categories.isLoading && categories.data && categories.data.length === 0 && <h2>NO CATEGORIES</h2>}
          </div>
        </Col>

        {edit.visible && (
          <Col xs={24} md={8}>
            <CategoryForm
              onSubmit={onSubmit}
              category={edit.category}
              categoryImage={image.data}
              token={currentuser.data.token}
              language={currentuser.language}
              setEdit={setEdit}
              SERVER_URL={SERVER_URL}
              fetchCategories={fetchCategories}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Categories;
