import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { Button, notification } from 'antd';
import TagForm from '../../components/forms/TagForm';

const EditTag = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [tag, fetchTag] = useAxios('', {}, currentuser.data.token, 'get');
  const [tagImage, fetchtagImage] = useAxios('', null, currentuser.data.token, 'get');
  const { imgId, tagId } = props.match.params;

  useEffect(() => {
    if (tagId) fetchTag(`${SERVER_URL}/tags/${tagId}`, {});
    if (imgId) fetchtagImage(`${SERVER_URL}/images/${imgId}`, {});
  }, [fetchTag, fetchtagImage, tagId, imgId]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/tags` : `${SERVER_URL}/tags/${tagId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Tag ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin/tags');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !tag.isLoading && tag.data && currentuser.language && !tagImage.isLoading;

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/tags'>
          <Button type='primary'>All tags</Button>
        </Link>
      </div>
      <div>
        {!tagId && isDataFetched && (
          <TagForm isNew={true} image={tagImage.data} language={currentuser.language} onSubmit={onSubmit} SERVER_URL={SERVER_URL} token={currentuser.data.token} />
        )}
        {tagId && !tag.isError && tag.data && isDataFetched && (
          <TagForm
            isNew={false}
            data={tag.data}
            image={tagImage.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditTag;
